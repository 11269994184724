<template>
  <div class="trips-groups-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/trip-groups">Trip groups</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.title_en }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div class="font-weight-bold text-h6 mt-4 px-4">{{ data.title_en }}</div>
          <div class="item-menu_item mt-4 px-5 d-flex align-center">
            <div class="gray--text">Filters</div>
            <v-spacer></v-spacer>
            <v-btn @click="modal = true" icon class="add-btn">
              <v-icon color="gray lighten-1">mdi-plus</v-icon>
            </v-btn>
          </div>
          <div
            v-for="item in menuItems"
            :key="item.title"
            :class="link == item.link ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu(item.link)"
            class="item-menu_item link"
          >
            <div>{{ item.title }}</div>
          </div>
          <div class="item-menu_item mt-6 px-5">
            <div class="gray--text">List</div>
          </div>
          <div :class="link == 'trips' ? 'gray lighten-4 pl-3 pr-5' : 'px-5'" @click="clickMenu('trips')" class="item-menu_item link">
            <div>Trips</div>
          </div>
        </v-card>
        <v-divider style="z-index: 90" vertical></v-divider>
        <div class="width100" v-if="link != 'trips' && menuItems.length">
          <div class="pl-lg-9 px-sm-7 py-sm-5 py-3 px-3 item-content">
            <div class="font-weight-bold text-h6">Filter Settings</div>
            <div class="text--text">Provide general information about the filter</div>
            <div class="font-weight-bold mt-5 mb-3">General Info</div>
            <v-row>
              <v-col cols="6" class="pb-0">
                <v-textarea
                  class="field46"
                  v-model="data.filters[link].title_en"
                  :error-messages="titleEnErrors"
                  placeholder="Enter title (eng)"
                  outlined
                  dense
                  required
                  color="primary"
                  auto-grow
                  rows="1"
                  row-height="36"
                ></v-textarea>
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-textarea
                  dir="rtl"
                  class="field46"
                  :error-messages="titleArErrors"
                  v-model="data.filters[link].title_ar"
                  placeholder="Enter title (ar)"
                  outlined
                  dense
                  required
                  color="primary"
                  auto-grow
                  rows="1"
                  row-height="36"
                ></v-textarea>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-textarea
                  class="field46"
                  v-model="data.filters[link].description_en"
                  :error-messages="descEnErrors"
                  placeholder="Enter description (eng)"
                  outlined
                  dense
                  required
                  color="primary"
                  auto-grow
                  rows="4"
                  row-height="36"
                ></v-textarea>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-textarea
                  dir="rtl"
                  class="field46"
                  :error-messages="descArErrors"
                  v-model="data.filters[link].description_ar"
                  placeholder="Enter description (ar)"
                  outlined
                  dense
                  required
                  color="primary"
                  auto-grow
                  rows="4"
                  row-height="36"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
          <div class="pl-lg-9 px-sm-7 pb-sm-5 pb-3 px-3">
            <div class="font-weight-bold mb-3">Picture</div>
            <div class="d-flex flex-wrap">
              <div class="me-4">
                <v-card
                  @click="onFileChange($event, 'u')"
                  flat
                  class="d-flex align-center justify-center card-img"
                  width="224"
                  height="232"
                >
                  <div class="primary--text text-center" v-if="!data.filters[link].picture">
                    <v-img max-width="44" class="mx-auto primary-filter" src="@/assets/icon/upload.svg"></v-img>
                    <div class="font-weight-bold">Upload image</div>
                    <div class="f13">.jpeg</div>
                  </div>
                  <img width="224" height="232" v-else :src="data.filters[link].picture" />
                  <input type="file" accept="image/*" @change="onFileChange($event, 'u')" />
                </v-card>
                <div class="text--text mt-2">(310x320)</div>
              </div>
              <div class="me-4">
                <v-card
                  @click="onFileChange($event, 'm')"
                  flat
                  class="d-flex align-center justify-center card-img"
                  width="270"
                  height="190"
                >
                  <div class="primary--text text-center" v-if="!data.filters[link].picture_middle">
                    <v-img max-width="44" class="mx-auto primary-filter" src="@/assets/icon/upload.svg"></v-img>
                    <div class="font-weight-bold">Upload image</div>
                    <div class="f13">.jpeg</div>
                  </div>
                  <img width="268" height="188" v-else :src="data.filters[link].picture_middle" />
                  <input type="file" accept="image/*" @change="onFileChange($event, 'm')" />
                </v-card>
                <div class="text--text mt-2">(374x252)</div>
              </div>
              <div class="me-4">
                <v-card
                  @click="onFileChange($event, 's')"
                  flat
                  class="d-flex align-center justify-center card-img"
                  width="262"
                  height="144"
                >
                  <div class="primary--text text-center" v-if="!data.filters[link].picture_small">
                    <v-img max-width="44" class="mx-auto primary-filter" src="@/assets/icon/upload.svg"></v-img>
                    <div class="font-weight-bold">Upload image</div>
                    <div class="f13">.jpeg</div>
                  </div>
                  <img width="260" height="142" v-else :src="data.filters[link].picture_small" />
                  <input type="file" accept="image/*" @change="onFileChange($event, 's')" />
                </v-card>
                <div class="text--text mt-2">(343x180)</div>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="pl-lg-9 px-sm-7 py-sm-5 py-3 px-3 d-flex flex-wrap">
            <v-btn @click="modalDelete = true" outlined class="rounded" width="126" height="30">
              <span class="error--text">Remove filter</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="updateFilterItem" depressed class="primary ms-3 rounded" width="106" height="30">Save</v-btn>
          </div>
        </div>
        <div class="width100" v-else>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="tripsList.result"
            item-key="id"
            :items-per-page="-1"
            :height="`calc(100vh - 240px)`"
          >
            <template v-slot:no-data v-if="!tripsList.pages">
              <div class="d-flex flex-column align-center justify-center height100 text-center">
                <img height="300" src="@/assets/img/bad.svg" alt="no data" />
                <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
              </div>
            </template>
            <template v-else v-slot:footer>
              <v-divider></v-divider>
              <div class="mt-2 pb-2 px-3 d-flex align-center">
                <div class="text--text">Total {{ tripsList.total }} trips</div>
                <v-spacer></v-spacer>
                <div class="d-flex align-center" v-if="tripsList.pages > 1">
                  <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="mx-4 text--text">{{ page }} of {{ tripsList.pages }}</span>
                  <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
    <v-dialog persistent v-model="modal" max-width="700">
      <v-card>
        <div class="font-weight-bold py-4 text-center">New filter</div>
        <v-divider></v-divider>
        <div class="px-10">
          <v-card
            @click="addImg"
            flat
            class="mx-auto mt-3 mb-6 d-flex align-center justify-center gray darken-5"
            max-width="270"
            width="100%"
            height="280"
          >
            <div class="white--text text-center" v-if="!image">
              <v-img max-width="44" class="mx-auto" src="@/assets/icon/upload.svg"></v-img>
              <div class="font-weight-bold">Upload image</div>
              <div class="f13">310x320.jpeg</div>
            </div>
            <v-img v-else :src="image"></v-img>
            <!-- <input type="file" accept="image/*" @change="onFileChange" /> -->
          </v-card>
          <div class="mb-3">Provide information in English and Arabic</div>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-textarea
                class="field46"
                v-model="modalData.title_en"
                :error-messages="titleEnErrors"
                placeholder="Enter title (eng)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="1"
                row-height="36"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                dir="rtl"
                class="field46"
                :error-messages="titleArErrors"
                v-model="modalData.title_ar"
                placeholder="Enter title (ar)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="1"
                row-height="36"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                class="field46"
                v-model="modalData.description_en"
                :error-messages="descEnErrors"
                placeholder="Enter description (eng)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="4"
                row-height="36"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                dir="rtl"
                class="field46"
                :error-messages="descArErrors"
                v-model="modalData.description_ar"
                placeholder="Enter description (ar)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="4"
                row-height="36"
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mt-5"></v-divider>
        <div class="text-end py-5 px-10">
          <v-btn @click="modal = false" class="rounded" outlined width="106" height="30">Cancel</v-btn>
          <v-btn
            @click="addFilterItem"
            :disabled="loading"
            :loading="loading"
            depressed
            class="primary ms-3 rounded"
            width="106"
            height="30"
          >
            <span v-if="!loading">Save</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalDelete" max-width="480">
      <v-card class="px-4 pt-4 pb-5" v-if="modalDelete">
        <div class="text-h6 font-weight-bold mb-3">Do you really want to delete {{ data.filters[link].title_en }} trip group filter?</div>
        <div class="text-end mt-5">
          <v-btn @click="modalDelete = false" class="rounded" outlined width="106" height="30">Cancel</v-btn>
          <v-btn @click="removeFilterItem" depressed class="error ms-3 rounded" width="106" height="30">Remove</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalDelete: false,
      loading: null,
      link: 0,
      page: 1,
      modalData: {},
      file: '',
      image: '',
      menuItems: [],
      headers: [
        { text: 'Trip', value: 'title_en', sortable: false },
        { text: 'Filters', value: '', sortable: false },
      ],
      statusError: null,
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getTripsGroupItem');
    this.getDataTrip();
  },
  methods: {
    notifi(btn) {
      if (btn == 'createFilter') {
        this.$notify({
          title: 'Done!',
          message: 'Trips group filter add successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'updateFilter') {
        this.$notify({
          title: 'Done!',
          message: 'Trips group filter updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'updateFilterPicture') {
        this.$notify({
          title: 'Done!',
          message: 'Trips group filter picture updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'removeFilter') {
        this.$notify({
          title: 'Done!',
          message: 'Trips group filter item remove',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    addImg() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async onFileChange(e, p) {
      this.file = e.target.files;
      if (!this.file.length) {
        return;
      } else {
        this.error = [];
        const formData = new FormData();
        formData.append('picture', this.file[0]);
        await this.$store
          .dispatch(
            p == 's' ? 'updateFiltersPictureSTripsGroup' : p == 'm' ? 'updateFiltersPictureMTripsGroup' : 'updateFiltersPictureTripsGroup',
            { id: this.data.filters[this.link].id, data: formData }
          )
          .then(() => {
            this.notifi('updateFilterPicture');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
    clickMenu(l) {
      this.link = l;
    },
    async addFilterItem() {
      this.error = [];
      this.loading = true;
      const formData = new FormData();
      for (var key in this.modalData) {
        formData.append(key, this.modalData[key]);
      }
      formData.append('picture', this.file);
      await this.$store
        .dispatch('setFiltersTripsGroup', formData)
        .then(() => {
          this.notifi('createFilter');
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        })
        .finally(() => {
          this.loading = null;
        });
    },
    async updateFilterItem() {
      this.error = [];
      const data = new Object();
      data.title_en = this.data.filters[this.link].title_en;
      data.title_ar = this.data.filters[this.link].title_ar;
      data.description_en = this.data.filters[this.link].description_en;
      data.description_ar = this.data.filters[this.link].description_ar;
      await this.$store
        .dispatch('updateFiltersTripsGroup', { id: this.data.filters[this.link].id, data: data })
        .then(() => {
          this.notifi('createFilter');
          this.modal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async removeFilterItem() {
      this.error = [];
      await this.$store
        .dispatch('deleteFiltersTripsGroup', this.data.filters[this.link].id)
        .then(() => {
          this.modalDelete = false;
          this.data.filters.splice(this.link, 1);
          this.menuItems.splice(this.link, 1);
          this.notifi('removeFilter');
          this.link == 0 ? '' : --this.link;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    nextPage() {
      if (this.page != this.tripsList.pages) {
        this.page += 1;
        this.getDataTrip();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getDataTrip();
      }
    },
    async getDataTrip() {
      this.statusError = null;
      await this.$store
        .dispatch('getTripsList', {
          tripGroup: this.$route.params.tripGroupsId,
          search: '',
          type: 'all',
          country: 'all',
          city: 'all',
          active: 'all',
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  watch: {
    data() {
      this.menuItems = [];
      for (let i = 0; i < this.data.filters.length; i++) {
        this.menuItems.push({
          link: i,
          title: this.data.filters[i].title_en,
        });
      }
    },
    modal() {
      this.error = [];
      this.file = '';
      this.image = '';
      this.modalData = {};
    },
  },
  computed: {
    data() {
      return this.$store.getters.tripsGroup;
    },
    tripsList() {
      return this.$store.getters.tripsList;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    descEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    descArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    pictureErrors() {
      const errors = [];
      this.error.find((item) => item == 'picture__required') && errors.push('Please select picture');
      this.error.find((item) => item == 'picture__invalid') && errors.push('Selected picture is not valid');
      this.error.find((item) => item == 'picture_middle__invalid') && errors.push('Selected picture is not valid');
      this.error.find((item) => item == 'picture_small__invalid') && errors.push('Selected picture is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setTripsGroupItem');
    this.$store.dispatch('setTripsList');
  },
};
</script>
<style lang="scss">
.trips-groups-item {
  .add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
    border: 1.4px solid var(--v-gray-lighten1);
    border-radius: 50px !important;
    i {
      font-size: 14px !important;
    }
  }
  .card-img {
    background: var(--v-primary-lighten3);
    border: 1px dashed var(--v-primary-base);
    img {
      object-fit: contain;
    }
  }
  th span {
    font-size: 16px;
    color: var(--v-text-base);
  }
  td {
    font-size: 15px !important;
  }
}
</style>
